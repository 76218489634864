<template>
  <v-container>
    <v-row class="headerTable-div2" style="width: 100%">
      <v-col md="4" sm="12">
        <h9 class="custom-header main-color">{{ title }}</h9>
      </v-col>
    </v-row>
    <!-- Children -->
    <v-btn
      :class="selected == stat ? 'isBlue' : ''"
      v-for="stat in statuses"
      :key="stat"
      rounded
      color="#EFEFEF"
      dark
      style="
        margin: 10px;
        margin-left: 0px;
        text-transform: capitalize;
        color: #979797;
        width: auto;
      "
      @click="whoSelected(stat)"
    >
      {{ stat }}
    </v-btn>
    <!-- Children -->
    <v-row>
      <v-col cols="12" style="position: relative">
        <v-data-table
          :headers="table.headersData"
          :items="dataTable"
          :options.sync="options"
          class="elevation-1 orderTable"
          item-key="id"
          hide-default-footer
        >
          <template
            v-slot:[`item.actions`]="{ item }"
            v-if="selected == 'Pending'"
            ><v-btn class="stat-btn" @click="update(item)">settle</v-btn>
          </template>
        </v-data-table>

        <div class="text-center pt-2">
          <v-pagination
            v-model="pagination.page"
            :length="pagination.length"
            :circle="true"
            :page="pagination.page"
            :total-visible="pagination.totalVisible"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
        </div>
      </v-col>
      <EmptySpace></EmptySpace>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="snack_color"
      rounded="pill"
      top
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- ---------------------end SnackBar--------------------- -->
    <v-row justify="center">
      <v-dialog v-model="confirm_dialog" max-width="500">
        <v-card>
          <v-card-title class="text-h6"> </v-card-title>
          <p
            style="
              padding-left: 20px !important ;
              padding-right: 20px !important;
            "
          >
            Are you sure you want to settle the request for
            <strong>{{ pending_parent_name }}</strong>
            ?
          </p>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="alert-cancel-btn"
              text
              @click="confirm_dialog = false"
            >
              No
            </v-btn>
            <v-btn class="alert-save-btn" text @click="changeRequestStatus">
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog></v-row
    >
  </v-container>
</template>

<script>
import EmptySpace from "../components/EmptySpace.vue";
import HttpService from "../services/HttpService.js";

export default {
  components: {
    EmptySpace,
  },
  props: ["order"],

  data() {
    return {
      children: 0,
      statuses: ["Pending", "Settled"],
      selected: "",
      message: "",
      snackbar: false,
      timeout: 10000,
      pending_parent_name: "",
      snack_color: "#ffffff",
      loading: false,
      valid: false,
      confirm_dialog: false,
      dataTable: [],
      selectedItem: {},
      title: "Refund Requests",
      status_button: "Settled",
      pagination: {
        page: 1,
        length: 0,
        totalVisible: "",
      },
      options: {
        itemsPerPage: 15,
      },
      table: {
        headersData: [
          {
            text: this.$i18n.t("Parent Name"),
            value: "parent_name",
            sortable: false,
            align: "center",
          },
          {
            text: this.$i18n.t("National ID"),
            value: "national_id",
            sortable: false,
            align: "center",
          },
          {
            text: this.$i18n.t("Email Address"),
            value: "email",
            sortable: false,
            align: "center",
          },
          {
            text: this.$i18n.t("Mobile Number"),
            value: "mobile_number",
            sortable: false,
            align: "center",
          },
          {
            text: this.$i18n.t("Request Date & Time"),
            value: "request_date",
            sortable: false,
            align: "center",
          },
          // {
          //   // text: this.selected == "Settled" ? "Settlement Date & Time" : "",
          //   text: this.$i18n.t("Settlement Date & Time"),
          //   value: "settlement_date",
          //   sortable: false,
          //   align: "center",
          // },
          {
            text: this.$i18n.t("Amount"),
            value: "amount",
            sortable: false,
            align: "center",
          },
          {
            text: this.$i18n.t(""),
            value: "actions",
            sortable: false,
            width: "12%",
            align: "center",
          },
        ],
        endpoints: {
          list: "getPendingRefundRequests",
        },
        activation: {
          isActive: "is Settled",
          isInactive: "is Pending",
        },
      },
    };
  },
  methods: {
    whoSelected(stat) {
      console.log("i clicked on = ", stat);
      this.selected = stat;
      if (this.selected == "Settled") {
        this.getSettledRequests();
      } else {
        this.getPendingRequests();
      }
    },

    getPendingRequests() {
      this.dataTable = [];
      if (this.table.headersData[5].value == "settlement_date") {
        console.log("in pending fun ", this.table.headersData[5]);
        this.table.headersData.splice(5, 1);
      }
      let queryString = "?page=" + this.pagination.page;
      HttpService.get(
        "wallet/getPendingRefundRequests",
        queryString,
        true
      ).then((response) => {
        // console.log(response);
        this.dataTable = response.data.data.data;
        this.setPaginationParameters(response);
      });
    },

    getSettledRequests() {
      this.dataTable = [];
      console.log("table = ", this.table.headersData);
      // fruits.splice(0, 3, "Lemon", "Kiwi");
      this.table.headersData.splice(5, 0, {
        text: this.$i18n.t("Settlement Date & Time"),
        value: "settlement_date",
        sortable: false,
        align: "center",
      });
      let queryString = "?page=" + this.pagination.page;
      HttpService.get(
        "wallet/getSettledRefundRequests",
        queryString,
        true
      ).then((response) => {
        // console.log(response);
        this.dataTable = response.data.data.data;
        this.setPaginationParameters(response);
      });
    },

    setPaginationParameters(response) {
      console.log("response from setPaginationParameters = ", response);
      // this.pagination.length = response.data.data.allTransfers.last_page;
      this.pagination.length = response.data.data.last_page;
      if (this.pagination.length > 5) this.pagination.totalVisible = 5;
    },

    update(item) {
      console.log("ite is = ", item);
      this.pending_parent_name = item.parent_name;
      this.selectedItem = item;
      // console.log("selectedItem = ", this.selectedItem);
      this.confirm_dialog = true;
    },

    changeRequestStatus() {
      //changeRequestStatus
      HttpService.post(
        "wallet/changeRequestStatus/" + this.selectedItem.request_id,
        "",
        true
      ).then((response) => {
        this.message = response.data.status.message;
        if (response.data.status.error == false) {
          this.confirm_dialog = false;
          this.snack_color = "success";
          this.snackbar = true;
          this.loading = false;
          // this.close();
          setTimeout(function () {
            window.location.reload();
            console.log("set time out");
          }, 10000);
        }
      });
    },

    deactivate(item) {
      console.log("item = ", item);
    },
  },
  created() {
    this.getPendingRequests();
    // this.getSettledRequests();
    this.selected = this.statuses[0];
    console.log("selected choice is = ", this.selected);
  },

  watch: {
    "pagination.page": {
      handler() {
        // this.getAllTransfers();
        if (this.selected == "Settled") {
          this.getSettledRequests();
        } else {
          this.getPendingRequests();
        }
      },
      deep: true,
    },
  },
  mounted() {},
};
</script>

<style>
.isBlue {
  color: #5a86ff !important;
  background-color: rgba(90, 134, 255, 0.3) !important;
}

.searchInput {
  border-radius: 30px;
  padding: 10px;
  /* width: 793px;
  height: 51px; */
}

.stat-btn {
  color: white !important;
  background-color: #7297ff !important;
  text-transform: capitalize;
}
</style>
