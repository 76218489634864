<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-container>
    <v-dialog v-model="addEditDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t(formTitle) }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form v-model="valid" ref="modalForm" class="py-4 modal-form">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Product Name") }} </label>
                  <v-autocomplete
                    v-model="editedItem.product_id"
                    :items="allProducts"
                    solo
                    persistent-hint
                    item-text="name"
                    item-value="id"
                  ></v-autocomplete>
                  <div
                    v-if="validation_errors.product_id != ''"
                    class="validation-div div-validation"
                  >
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.product_id"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Canteen") }} </label>
                  <v-select
                    v-model="editedItem.canteen_id"
                    :items="allCanteens"
                    solo
                    persistent-hint
                    item-text="name"
                    item-value="id"
                  ></v-select>
                  <div
                    v-if="validation_errors.canteen_id != ''"
                    class="validation-div div-validation"
                  >
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.canteen_id"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Quantity") }} </label>
                  <v-text-field
                    v-model="editedItem.quantity"
                    :rules="[
                      validationRules.required,
                      validationRules.onlyIntegers,
                    ]"
                    @focus="onFocus()"
                    solo
                  ></v-text-field>
                  <div
                    v-if="validation_errors.quantity != ''"
                    class="validation-div div-validation"
                  >
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.quantity"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Buying Price Per Unit") }} </label>
                  <v-text-field
                    v-model="editedItem.buying_price"
                    :rules="[
                      validationRules.required,
                      validationRules.positiveFloatNumber,
                    ]"
                    @focus="onFocus()"
                    solo
                    :disabled="editedIndex > -1"
                  ></v-text-field>
                  <div
                    v-if="validation_errors.buying_price != ''"
                    class="validation-div div-validation"
                  >
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.buying_price"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn class="modal-btn-cancel" @click="close">{{
            $t("Close")
          }}</v-btn>
          <v-btn
            class="modal-btn-save"
            @click="save"
            :loading="loading"
            :disabled="loading"
            >{{ $t("Save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="snack_color"
      rounded="pill"
      top
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-row justify="center">
      <!-- /////////////////// -->
      <!-- <v-dialog v-model="confirm_dialog" max-width="300">
        <v-card>
          <v-card-title
            class="text-h6 custom-header main-color font-weight-bold"
          >
            Reverse Points
          </v-card-title>
          <v-card-text class="font-weight-normal reverse-dialog"
            >Are you sure you want to retrieve the points back to your current
            balance?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="alert-cancel-btn"
              text
              @click="confirm_dialog = false"
            >
              No
            </v-btn>
            <v-btn class="alert-save-btn" text @click="reverseConfirmation">
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
      <!-- /////////////////// -->
      <v-dialog v-model="confirm_dialog" max-width="500">
        <v-card>
          <v-card-title
            class="text-h6"
            :v-html="notifyMSG"
            style="word-break: break-word"
          >
            <!-- in body not title -->
          </v-card-title>
          <v-card-text class="font-weight-normal reverse-dialog">{{
            notifyMSG
          }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="alert-cancel-btn"
              text
              @click="confirm_dialog = false"
            >
              No
            </v-btn>
            <v-btn class="alert-save-btn" text @click="confirmStore">
              Continue
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { validationMixin } from "../mixins/validation-mixin";
import HttpService from "../services/HttpService.js";
// import ACL from "../acl.js";
export default {
  mixins: [validationMixin],
  props: ["addEditDialog", "editedIndex"],
  data: () => ({
    message: "",
    snackbar: false,
    timeout: 10000,
    snack_color: "success",
    loading: false,
    valid: false,
    confirm_dialog: false,
    // editedIndex: -1,
    editedItem: {
      buying_price: "",
      quantity: "",
      product_id: "",
      canteen_id: "",
      confirmed: "",
    },
    defaultItem: {
      buying_price: "",
      quantity: "",
      product_id: "",
      canteen_id: "",
      confirmed: "",
    },
    validation_errors: {
      buying_price: [],
      quantity: [],
      product_id: [],
      canteen_id: [],
    },
    auth_id: localStorage.auth_id,
    allCanteens: "",
    allProducts: "",
    notifyMSG: "",
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Item" : "Edit Item";
    },
  },

  methods: {
    setEditedItem(item) {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, item);
        // this.editedItem = item;
        this.staff = this.staff.concat(this.editedItem.canteen_staff_edit);
      });
      this.validation_errors = Object.assign(
        {},
        {
          buying_price: [],
          quantity: [],
          product_id: [],
          canteen_id: [],
        }
      );
    },
    onFocus() {
      this.$refs.modalForm.resetValidation();
    },

    close() {
      // Child Shouldn't change parent property which is passed as props
      // this.addEditDialog = false;
      // Instead, It should call an event to call a function in parent to change the proerty to false
      this.$emit("closeDialog");
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
      this.$refs.modalForm.resetValidation();
      this.validation_errors = Object.assign(
        {},
        {
          buying_price: [],
          quantity: [],
          product_id: [],
          canteen_id: [],
        }
      );
      this.confirm_dialog = false;
    },

    save() {
      if (this.editedIndex > -1) {
        // edit mode
        this.update();
      } else {
        // create mode
        this.store();
      }
    },

    store() {
      if (this.valid) {
        this.loading = true;
        HttpService.post("stores", this.editedItem, true).then((response) => {
          this.message = response.data.status.message;
          if (response.data.status.error == false) {
            this.snack_color = "success";
            this.snackbar = true;
            this.loading = false;
            this.close();
          } else {
            this.loading = false;
            // if there's an error
            if (
              Object.keys(response.data.status.validation_errors).length > 0
            ) {
              // if there's validation errors from backend
              this.validation_errors = response.data.status.validation_errors;
            } else {
              // if there's an error but it's not validation error
              //show yes or no dailog ("msg of BE")
              this.notifyMSG = response.data.status.message;
              this.message = "";
              this.confirm_dialog = true;

              // this.message = response.data.status.message;
              // this.snack_color = "error";
              // this.snackbar = true;
            }
          }
          this.$emit("getData");
        });
      } else {
        this.$refs.modalForm.validate();
      }
    },

    //onclick of yes  call this
    confirmStore() {
      // if (this.valid) {
      this.editedItem.confirmed = true;
      HttpService.post("stores", this.editedItem, true).then((response) => {
        this.message = response.data.status.message;
        if (response.data.status.error == false) {
          this.snack_color = "success";
          this.snackbar = true;
          //close yes or no dialog
          this.close();
        } else {
          // if there's an error
          if (Object.keys(response.data.status.validation_errors).length > 0) {
            // if there's validation errors from backend
            this.validation_errors = response.data.status.validation_errors;
          } else {
            // if there's an error but it's not validation error
            this.snack_color = "error";
            this.snackbar = true;
          }
        }
        this.$emit("getData");
      });
      // } else {
      //   this.$refs.modalForm.validate();
      // }
    },

    getAllCanteens() {
      HttpService.get("stores/allCanteens", "", true).then((response) => {
        this.allCanteens = response.data.data;
        console.log("my allCanteens is = ", this.allCanteens);
      });
    },

    getAllProducts() {
      HttpService.get("stores/allProducts", "", true).then((response) => {
        this.allProducts = response.data.data;
        console.log("my allProducts is = ", this.allProducts);
      });
    },
  },

  created() {
    // var id = localStorage.auth_id;
    // console.log("auth_id = ", id);
    this.getAllCanteens();
    this.getAllProducts();
  },
};
</script>
<style></style>
